<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">反馈详情</div>
    </div>
    <div class="time">{{ infoData.handleTime }}</div>

    <div class="title" v-if="infoData.type === 0">产品体验问题</div>
    <div class="title" v-if="infoData.type === 1">内容问题</div>
    <div class="title" v-if="infoData.type === 2">其它问题</div>
    <div class="content">
      {{ infoData.content }}
    </div>
    <div class="describe">反馈结果描述：</div>
    <div class="result" v-if="infoData.feedback != ''">
      {{ infoData.feedback }}
    </div>
  </div>
</template>

<script>
import { recommendInfo } from "../../../api/circle";
export default {
  data() {
    return {
      infoData: {},
    };
  },
  mounted() {
    recommendInfo({ id: this.$route.query.id }).then((res) => {
      console.log(res, "反馈详情");
      this.infoData = res.data.data;
    });
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/feedbackPage" });
    },
  },
};
</script>

<style scoped>
.setBox {
  margin-bottom: 100px;
}
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 232px;
}
.time {
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-top: 48px;
  margin-left: 32px;
}
.title {
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-top: 30px;
  margin-left: 32px;
}
.content {
  width: 686px;
  font-size: 28px;
  font-weight: 400;
  color: #455669;
  margin-left: 32px;
  margin-top: 24px;
  line-height: 44px;
}
.describe {
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 40px;
}
.result {
  width: 686px;
  height: 222px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 32px;
  margin-left: 32px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  padding: 32px;
  box-sizing: border-box;
}
</style>
